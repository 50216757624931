export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state: {
        educanbooks: '',
        educanbook: '', 
    },
    
    getters: {
        educanbooks: (state) => state.educanbooks,
        educanbook: (state) => state.educanbook,
    },

    mutations: { 
        seteducanbooks: (state, educanbooks) => (state.educanbooks = educanbooks), 
        seteducanbook: (state, educanbook) => (state.educanbook = educanbook), 
    },

    actions: {
        async create_educanbook({ commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_educanbook')
            return new Promise((resolve, reject) => {
                axios.post("/entrant/educanbooks/create", data)
                .then(response => {    
                    commit('setloader', false)
                    if(response.data) {
                        commit('seteducanbooks', response.data.res)
                        toast.success("Book Added Successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data) {
                            if(error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if(error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });           
        },

        async get_educanbooks({ commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_educanbooks')
            return new Promise((resolve, reject) => {
                axios.post("/entrant/educanbooks?page=" + page, data)
                .then(response => {    
                    commit('setloader', false)
                    if(response.data) {
                        commit('seteducanbooks', response.data.res)
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data) {
                            if(error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if(error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });           
        },

        async get_educanbook({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_educanbook' + id)
            return new Promise((resolve, reject) => {
                axios.post("/entrant/educanbooks/" + id, data)
                .then(response => {    
                    commit('setloader', false)
                    if(response.data) {
                        commit('seteducanbook', response.data.res)
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data) {
                            if(error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if(error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });           
        },

        async update_educanbook({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_educanbook' + id)
            return new Promise((resolve, reject) => {
                axios.post("/entrant/educanbooks/update/" + id, data)
                .then(response => {    
                    commit('setloader', false)
                    if(response.data) {
                        commit('seteducanbooks', response.data.res)
                        toast.success("Book Updated Successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data) {
                            if(error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if(error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });           
        },

        async delete_educanbook({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_educanbook' + id)
            return new Promise((resolve, reject) => {
                axios.post("/entrant/educanbooks/delete/" + id, data)
                .then(response => {    
                    commit('setloader', false)
                    if(response.data) {
                        commit('seteducanbooks', response.data.res)
                        toast.success("Book Deleted Successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data) {
                            if(error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if(error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });           
        },
    }
}